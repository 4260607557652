.request-form {
  & .headers, & .body, & .query, & .output {
    .title {
      background: #f4f9fe;
      margin: 15px 0;
      font-weight: bold;
    }
  }

  & .btn {
    border-radius: 4px;
    border: 1px solid rgb(244, 74, 207);
    color: rgb(244, 74, 207);
    background: none;
    margin-top: 15px;
  }
}

.test-endpoint-form {
  & .headers, & .body, & .query, & .output {
    .title {
      background: #f4f9fe;
      margin: 15px 0;
      font-weight: bold;
    }
  }

  & .btn {
    border-radius: 4px;
    border: 1px solid rgb(244, 74, 207);
    color: rgb(244, 74, 207);
    background: none;
    margin-top: 15px;
  }
}