.btn-grad {
  background-image: linear-gradient(180deg, #dd4ae0 0%, #e14a6e 100%);
}

.mk-pagination {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 15px;
}

.btn-grad {
  padding: 10px 37px;
  text-align: center;
  transition: 0.5s;
  color: white;
  box-shadow: 0 0 20px #eee;
  border: none;
  border-radius: 6px;
  display: block;
}

.banner {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > .banner-text {
    color: white;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
  }
  > .banner-des {
    color: white;
    font-size: 20px;
    padding-top: 16px;
    line-height: 30px;
  }
}

.search-bar {
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  margin-top: -30px;
  // margin-top: 40px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.popular {
  display: flex;
  padding-top: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  > .label {
    color: #898989;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }
  > .item-wrap {
    border: 1px solid #f44acf;
    border-radius: 4px;
    margin-left: 12px;
    > .popular-item {
      color: #f44acf;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 14px;
    }
  }
}

.market {
  .market-content {
    padding: 16px 40px 40px;
    flex-direction: row;
    display: flex;
  }

  .filter {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    justify-content: center;
    padding-bottom: 40px;
    .ant-select-selection-placeholder {
      font-weight: 500;
      font-size: 16px;
      color: #000000;
    }
    .select-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      .filter-title {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
        margin-right: 10px;
      }

      .ant-select-selection-placeholder {
        font-size: 14px;
        color: #999;
      }
    }

    .select-filter {
      margin-right: 40px;
    }

    .select-package {
      padding-top: 10px;
      height: 50px;
      width: 235px;
      &.view {
        width: 115px;
      }
      border: 1px solid #e8e8e8;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(176, 219, 243, 0.25);
      border-radius: 4px;
    }
  }

  .not-found {
    text-align: center;
    padding-top: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  body {
    height: auto;
  }

  .vitual-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .ant-card {
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }

  .market-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    margin: 0 40px;

    .left {
      h4 {
        font-weight: bold;
        font-size: 24px;
        margin: 0px;
      }
    }

    .right {
      display: flex;

      button {
        margin-left: 12px;
        button {
          margin-left: 0px;
        }
      }
    }
  }

  .img-cover {
    object-fit: fill;
    height: 100px;
  }

  .txt-label {
    color: '#686868';
    font-size: 16px;
  }

  .demo {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #f44acf;
  }

  .hd-ai-services {
    background: #e124e4; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #e124e4, #e31949); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(135deg, #e124e4, #e31949);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 600;
  }

  .categories {
    margin-top: 12px;
    margin-right: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 6px;

    .cate-header {
      display: flex;
      padding-left: 40px;
      padding-right: 20px;
      padding-top: 24px;
      padding-bottom: 16px;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      &.column {
        flex-direction: column;
        align-items: baseline;
      }
      .cate-filter-header {
        flex-direction: row;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      align-items: center;
      border-bottom: 1px solid rgba(199, 199, 199, 0.35);
    }

    .cate-sub-header {
      align-items: center;
      display: flex;
    }

    .cate-option {
      font-weight: 500;
      font-size: 16px;
      color: #58686f;
    }

    .selected-filter {
      flex-direction: row;
      padding-top: 8px;
      flex-wrap: wrap;
      width: 100%;
      display: flex;
      align-items: center;

      .cate-filter {
        color: #fff;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .cate-label {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      color: #333333;
    }

    .cate-sub-label {
      font-weight: 500;
      font-size: 18px;
      color: #181818;
    }

    .cate-clear {
      font-weight: 500;
      font-size: 14px;
      color: #58686f;
      cursor: pointer;
    }

    .grad-filter {
      margin-top: 8px;
      margin-right: 8px;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
      align-items: center;
      text-align: center;
      transition: 0.5s;
      color: white;
      box-shadow: 0 0 20px #eee;
      border: none;
      border-radius: 6px;
      display: block;
      background-image: linear-gradient(180deg, #dd4ae0 0%, #e14a6e 100%);
    }
  }

  // @media only screen and (min-width: 1425px) {
  //   .card-ai-market {
  //     width: 250px;
  //   }
  // }

  // @media only screen and (min-width: 1905px) {
  //   .card-ai-market {
  //     width: 280px;
  //   }
  // }
}

.card-ai-market {
  width: 270px;
  border-radius: 8px;
  margin-right: 30px;
  overflow: hidden;
  height: 298px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  &.list {
    width: 100%;
    height: 160px;
    margin-bottom: 40px;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    height: 225px;
    width: 270px;
    &.row {
      width: 100%;
      height: auto;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .card-cover {
    padding: 15px;
    &.list {
      width: 50%;
      display: flex;
      flex-direction: row;
      padding: 0px;
      padding-right: 25px;
    }
  }

  .card-description {
    padding-left: 25px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }

  .card-txt-des {
    width: 60%;
  }

  .card-brief {
    padding-top: 8px;
  }

  .card-user-info {
    display: flex;
    flex-direction: row;
  }

  .card-user-name {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
  }

  .card-name {
    width: auto;
    background: #f4f9fe;
    padding: 6px;
  }

  .txt-username {
    font-weight: 600;
    font-size: 14px;
    color: #181818;
    &.light {
      color: #686868;
    }
    &.list {
      align-items: center;
      margin-right: 8px;
    }
  }

  .txt-verified {
    font-weight: 500;
    font-size: 12px;
    color: #00ba00;
  }

  .rating {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .txt-rating {
    font-weight: 500;
    font-size: 16px;
    color: #181818;
    margin-left: 8px;
  }

  .btn-grad {
    padding: 4px 12px;
  }

  // .ant-card-body::before {
  //   content: none;
  // }

  // .ant-card-body::after {
  //   content: none;
  // }

  .txt-title {
    color: #333333;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 600;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .txt-content {
    color: #333333;
    font-size: 16px;
  }

  .txt-description {
    color: #58686f;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;

    // white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    max-height: 58px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .divider {
    height: 1px;
    width: 100%;
    background: #ebebeb;
    margin: 12px 0;
    &.list {
      width: 1px;
      height: auto;
      margin: 0px;
    }
  }

  .bottom-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.list {
      align-items: flex-start;
    }

    .price {
      font-size: 16px;
      font-weight: normal;
      color: #3e93e1;
    }

    .your-created {
      font-size: 16px;
      font-weight: normal;
      color: #b3b3b3;
    }
  }

  // .ant-card-body {
  //   padding: 16px;
  //   height: 200px;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;

  //   .txt-title {
  //     color: #333333;
  //     margin-bottom: 4px;
  //     text-overflow: ellipsis;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 2; /* number of lines to show */
  //     line-clamp: 2;
  //     -webkit-box-orient: vertical;
  //     font-weight: 600;
  //     font-size: 18px;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //   }

  //   .txt-content {
  //     color: #333333;
  //     font-size: 16px;
  //   }

  //   .txt-description {
  //     color: #58686f;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     display: -webkit-box;
  //     white-space: nowrap;
  //     font-weight: 500;
  //     font-size: 14px;
  //     line-height: 21px;
  //     max-height: 48px;
  //     -webkit-line-clamp: 2;
  //     -webkit-box-orient: vertical;
  //   }

  //   .meta {
  //     align-items: center;
  //     display: flex;

  //     p {
  //       margin: 0;
  //       margin-left: 8px;
  //     }
  //   }

  //   .divider {
  //     height: 1px;
  //     width: 100%;
  //     background: #ebebeb;
  //     margin: 12px 0;
  //   }

  //   .bottom-group {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: space-between;
  //     align-items: center;

  //     .price {
  //       font-size: 16px;
  //       font-weight: normal;
  //       color: #3e93e1;
  //     }

  //     .your-created {
  //       font-size: 16px;
  //       font-weight: normal;
  //       color: #b3b3b3;
  //     }
  //   }
  // }
}
