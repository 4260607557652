.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}

.inputSearch {
  border: none;
  width: 100%;
  height: 60px;
}

.menu-bar {
  padding: 16px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  background: white;
  position: fixed;
  width: 100%;
  z-index: 999;
  box-shadow: 0px 4px 12px 0px #d5c0dd40;
}

.menu-items {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.items {
  padding-right: 30px;
  display: flex;
}

.left-header {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.menu {
  display: flex;
  align-items: center;
}

.logo-text {
  padding-left: 10px;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  // text-decoration: none;
  &.light {
    color: #686868;
    padding-left: 40px;
  }
  &.white {
    color: #fff;
    font-size: 14px;
    padding-left: 0px;
  }
}

.hd-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active {
  color: #f44acf !important;
}

.bd-bottom {
  background: #f44acf;
  width: 20px;
  height: 2px;
  margin-left: 40px;
}

.btn-group {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;

  > .btn-grad {
    background-image: linear-gradient(180deg, #dd4ae0 0%, #e14a6e 100%);
    padding: 0px 37px;
    height: 40px;
    text-align: center;
    transition: 0.5s;
    color: white;
    box-shadow: 0 0 20px #eee;
    border: none;
    border-radius: 6px;
    display: block;
  }
  .btn-grad:hover {
    cursor: pointer;
  }
  .btn-txt {
    font-weight: 600;
    font-size: 13px;
    color: #ffffff;
  }
  .free-btn {
    background: #ffffff;
    padding: 0px 37px;
    height: 40px;
    border: 1px solid #f44acf;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 24px;
  }

  .free-btn:hover {
    cursor: pointer;
  }

  .free-txt {
    font-size: 14px;
    color: #f44acf;
  }
}

.logout-btn {
  background: #ffffff;
  padding: 5px 15px;
  // height: 40px;
  border: 1px solid #f44acf;
  box-sizing: border-box;
  border-radius: 4px;
}

.logout-btn:hover {
  cursor: pointer;
}

.logout-txt {
  font-size: 14px;
  color: #f44acf;
}

.hd-container {
  background: #fff;
  border-radius: 8px;
  .hd-title {
    padding: 15px;
    padding-bottom: 0px;
    border-bottom: 1px solid #bdc3d3;
  }
  .hd-label {
    color: #181818;
    font-weight: 600;
    font-size: 14px;
  }
  .hd-wallet {
    color: #181818;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 13px;
  }
  .hd-info-right {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    .hd-balance {
      font-weight: 600;
      font-size: 13px;
      color: #9a9a9a;
    }
    .hd-orai {
      background: #26cbff; /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, #26cbff, #176dff); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(135deg, #26cbff, #176dff);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: 600;
    }
  }
  .hd-wallet-info {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
  }

  .hd-circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: linear-gradient(99.99deg, #005dfa, #009cfd 98.89%);
    margin-right: 6px;
  }
  .hd-dropdown {
    padding: 15px;
    height: auto;
    width: auto;
    border-radius: 8px;
    background: #fff;

    .hd-menu-item {
      border-bottom: 1px solid #bdc3d3;
    }

    .hd-logout {
      padding-top: 15px;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .hd-label {
      font-weight: bold;
    }

    .hd-content {
      font-weight: normal;
      display: flex;
      font-size: 12px;
    }
  }

  .hd-copy {
    background: #fff;
    border: none;
  }
}
