.sso_widget {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 65px;

  iframe {
    border: 0px;
    width: 100%;
    height: 50px;
  }
}