.as-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.upload-thumb {
  padding-top: 30px;
}

.drag-zone {
  padding-top: 15px;
}

.as-image-container {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  align-items: center;
  justify-content: center;
  .as-image {
    border-radius: 8px;
    object-fit: cover;
    height: 200px;
    width: 200px;
  }

  .as-btn {
    background: #fff;
    border: none;
  }
}

.as-content {
  margin-top: 20px;
  background: #ffffff;
  border: 1px dashed #c6daed;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px;
}

.as-bd-bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 1px;
  margin-top: 4px;

  > .as-bd-st {
    height: 1px;
    width: 10%;
  }

  > .as-bd-lt {
    height: 1px;
    width: 90%;
  }
}

.as-label {
  margin: 5px 0;
  font-weight: bold;
  color: #7d7d7d;
}

.as-require {
  color: #dc143c;
}

.as-row {
  margin-top: 30px;
}

.as-input {
  border-radius: 4px;
  border: 1px solid #e9e9e9 !important;
  &.small {
    width: 95%;
  }
}

.service-management {
  display: flex;

  .content-wrappper {
    padding-left: 270px;
    padding-top: 100px;
    width: 100%;
  }

  & .menu_management {
    width: 240px;
    background-color: #fff;
    margin-right: 30px;
    position: fixed;
    margin-top: 100px;
    box-shadow: 0px 4px 20px 0px #f1f5f8;
    border-radius: 8px;
    overflow: hidden;

    & span,
    a {
      color: #181818;
    }

    & .ant-menu-sub {
      background-color: #fff;
    }

    & .header {
      min-height: 93px;
      border-bottom: 1px solid #ebf4ff;
    }

    .create-service {
      min-height: 40px;
      align-items: center;
      justify-content: flex-start;
      padding-left: 24px;
      display: flex;
      .txt {
        margin-left: 8px;
      }
    }

    .header {
      display: flex;
      align-items: center;
      padding: 12px 20px;

      & .avatar_wrapper {
        background: #f8fafb;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        padding-bottom: 5px;
        margin-right: 12px;
      }

      & .username {
        color: #333333;
        font-weight: bold;
        text-align: center;
      }
    }

    & .ant-menu-item-selected {
      background: #ffeffc !important;
      & span,
      a {
        color: #e124e4 !important;
        font-weight: bold;
      }
    }
  }

  & .ant-tabs-nav {
    height: 60px;
  }

  & .ant-tabs-tab {
    padding: 20px;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #e124e4;
    font-weight: bold;
  }

  & .ant-tabs-tab:hover {
    color: #000;
  }

  & .ant-tabs-ink-bar {
    background: #e124e4;
  }

  & .ant-tabs {
    width: 100%;
    background: #f8fafb;
    padding-bottom: 50px;
  }

  & .endpoints {
    display: flex;
    flex-direction: column;
    align-items: end;

    & .btn_add-endpoint {
      width: 130px;
    }

    & .tbl_list-endpoints {
      width: 100%;
      margin-top: 15px;
    }

    & .input_item {
      margin-top: 15px;
      width: 100%;
    }

    & .btn_save-endpoint {
      margin-left: 10px;
    }
  }
  .btn-grad {
    background-image: linear-gradient(180deg, #dd4ae0 0%, #e14a6e 100%);
    padding: 0px 37px;
    height: 40px;
    text-align: center;
    transition: 0.5s;
    color: white;
    box-shadow: 0 0 20px #eee;
    border: none;
    border-radius: 6px;
    display: block;
    &.disabled {
      background-image: linear-gradient(180deg, #d7d7d7 0%, #d7d7d7 100%);
    }
    > .btn-txt {
      font-weight: 600;
      font-size: 13px;
      color: #ffffff;
    }
  }

  & .btn {
    border-radius: 4px;
    border: 1px solid rgb(244, 74, 207);
    color: rgb(244, 74, 207);
    background: none;
    margin-top: 15px;
  }

  & .ant-select {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  & .tab_definition {
    width: 100%;
  }

  & .ant-table-thead > tr > th {
    background: #f3f7fa;
  }

  & .ant-menu-dark .ant-menu-item > span > a {
    color: #000;
  }
}

.claim-body {
  width: 100%;
  padding-left: 40px;

  h3 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .claim-body-wrapper {
    width: 100%;

    .ant-table-wrapper {
      width: 100%;
    }
  }
}

.my-services {
  width: 100%;

  & .ant-steps {
    height: 50px;
  }
}
