.my-services {
  .service-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .ant-steps {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #f8eff6;

    .ant-steps-item-finish .ant-steps-item-icon {
      border-color: #f44acf;
    }

    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: #f44acf;
    }

    .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title {
      color: #f44acf;
    }

    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
      > .ant-steps-item-container[role='button']:hover
      .ant-steps-item-icon {
      border-color: #f44acf;
    }

    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
      > .ant-steps-item-container[role='button']:hover
      .ant-steps-item-icon
      > .ant-steps-icon {
      color: #f44acf;
    }
  }

  .ant-steps-navigation .ant-steps-item::before {
    background-color: #f44acf;
  }

  .select-package {
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    width: 95%;
  }

  .btn_sell {
    margin-right: 20px;
  }

  .package-management {
    margin-top: 40px;

    .ant-row {
      background: #fff;
    }
    .content-wrapper {
      padding-top: 10px;
      padding-left: 7px;
    }

    .primary-button {
      background: linear-gradient(180deg, #dd4ae0 0%, #e14a6e 100%);
      border-radius: 4px;
      border: none;
      font-size: 16px;
      color: white;
      height: auto;
      padding: 8px 12px;

      span {
        color: white;
        font-weight: 500;
      }
    }

    .secondary-button {
      background: white;
      border-color: #f44acf;
      border-radius: 4px;
      border-width: 1px;

      span {
        font-weight: 500;
        color: #f44acf;
        &.disabled {
          color: #d7d7d7;
        }
      }

      &.disabled {
        border-color: #d7d7d7;
      }
    }

    .ml-btn {
      margin-left: 12px;
    }

    .ant-checkbox-wrapper:hover,
    .ant-checkbox-wrapper:focus {
      .ant-checkbox-inner {
        border-color: #f44acf;
      }
    }

    .ant-checkbox-wrapper .ant-checkbox:focus {
      border-color: #f44acf;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: #f44acf;
      background: #f44acf;
    }

    .btn-group {
      display: flex;
      flex-direction: row;
      width: auto;
    }
  }

  .endpoints {
    .select_content-type {
      display: block;
      width: 15%;
      margin-top: 10px;
    }

    .by-json {
      margin-top: 15px;
    }
  }

  .package-metadata-header {
    background: #f1f6f9;
    color: #58686f;

    .ant-row {
      background: #f1f6f9;
      border-radius: 4px;
    }
  }

  .status {
    background-color: white;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    .status-txt {
      font-weight: 500;
      font-size: 14px;
      color: #58686f;
      &.verified {
        color: #04c752;
      }
      &.pending {
        color: #ffa723;
      }
    }
    .ic-status {
      color: #58686f;
      margin-right: 6px;

      &.verified {
        color: #04c752;
      }
      &.pending {
        color: #ffa723;
      }
    }
  }
  .base-url {
    padding: 15px;
  }

  .endpoints {
    padding: 15px;
    padding-top: 0px;
  }

  .as-content {
    margin-bottom: 40px;
  }

  .group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
