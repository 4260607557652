.ai-dashboard {
  padding: 50px 20px;
  width: 100%;

  .title {
    font-size: 36px;
    font-weight: bold;
  }

  .header-card {
    width: 100%;
    background: white;
    padding: 16px 24px;
    box-shadow: 0px 4px 8px rgba(217, 217, 217, 0.25);
    border-radius: 4px;

    .card-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0px;
    }

    .card-number {
      font-size: 24px;
    }

    .additional-info {
      margin-top: 12px;

      span {
        font-size: 13px;
      }

      .card-changed-number {
        color: #00c34e;
      }
    }

    svg {
      position: absolute;
      top: 10px;
      right: 30px;
    }
  }

  .main-chart,
  .news {
    h5 {
      font-weight: bold;
      font-size: 20px;
    }

    .chart-wrapper {
      background: white;
      padding: 16px;
    }
  }
}

.news {
  .news-card {
    background: white;
    padding: 16px;
    display: flex;
    margin-bottom: 16px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 8px;
      margin-right: 12px;
    }

    .news-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0px;
      color: #555555;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .news-content {
      font-size: 13px;
      font-weight: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }

    a {
      font-size: 12px;
    }
  }
}

.add-service-overview {
  .select-package {
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    width: 95%;
  }
}

.drag-zone {
  display: flex;
  align-items: center;
  justify-content: center;
}
