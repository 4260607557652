.App {
  // text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  max-width: 1440px;
}

.spin {
  margin-top: 30px !important;
  display: block !important;
}

.primary-button {
  background: linear-gradient(180deg, #dd4ae0 0%, #e14a6e 100%);
  border-radius: 4px;
  border: none;
  font-size: 16px;
  color: white;
  height: auto;
  padding: 8px 12px;

  span {
    color: white;
    font-weight: 500;
  }
}

.secondary-button {
  background: white;
  border-color: #f44acf;
  border-radius: 4px;

  span {
    font-weight: 500;
    color: #f44acf;
  }
}

.ant-modal-root {
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
  }

  .ant-btn-primary {
    background: linear-gradient(180deg, #dd4ae0 0%, #e14a6e 100%) !important;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    color: white;
  }

  .ant-btn {
    background: white;
    border-color: #d5d5d5;
    border-radius: 4px;
    padding: 8px 12px;
    height: auto;
  }
}
